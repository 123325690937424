<template>
    <div class="summary-introduce">
        <global-page-back
            v-if="isEdit"
            detailPageTitle="简介"
            @handlerGlobalType="cancel"
        ></global-page-back>
        <div class="presentation">
            <div v-if="!isEdit && !isEmpty" class="img-wrapper" v-hasPermi="['schoolIntroduction:edit']" @click="edit">
                <img :src="imgEdit" alt="edit" />
                <p class="hover-color">编辑</p>
            </div>
            <div v-if="!isEdit" class="introduce-title-wrapper">
                <template v-if="!isEmpty">
                    <div v-if="showData.title" class="introduce-title" >
                        {{ showData.title }}
                    </div>
                    <div class="text-conter">
                        <p v-if="showData.content" class="introduce-content wangeditor" v-html="showData.content"></p>
                    </div>
                </template>
                <div
                    v-else-if="!loading"
                    class="introduce-empty"
                >
                    <p>通过简明扼要的文字</p>
                    <p>全面的介绍学校的基本情况</p>
                    <p>描述学校的建校历史、教学文化、荣誉等</p>
                    <p>赶快去创建吧~</p>
                    <el-button
                        type="primary"
                        class="create-btn"
                        @click="edit()"
                    >去创建</el-button>
                </div>
            </div>
            <!--编辑-->
            <div v-else class="edit">
                <el-form ref="formRef" :model="rulesForm" :rules="rules" label-width="120px">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="rulesForm.title" placeholder="标题" :maxlength="50" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="内容" prop="content">
                        <rich-editor v-model="rulesForm.content" height="calc(100vh - 320px)"/>
                    </el-form-item>
                    <el-form-item style="width: 100%">
                        <xk-button marginLeft="0px" type="primary" @click="save">保存</xk-button>
                        <xk-button type="warning"  @click="preview">预览</xk-button>
                        <xk-button type="info" @click="cancel">取消</xk-button>
                    </el-form-item>
                </el-form>
            </div>
            <!--预览-->
            <dialog-wrapper :dialog-obj="dialogObj" @handleClose="preview">
                <p v-if="rulesForm.title" class="introduce-title">{{ rulesForm.title }}</p>
                <p v-if="rulesForm.content" class="introduce-content" v-html="rulesForm.content"></p>
            </dialog-wrapper>
        </div>
    </div>
</template>

<script>
    import { DialogWrapper } from 'common-local'
    import GlobalPageBack from '@/components/scrollWrapper/Sub/GlobalPageBack/index.vue';
    import RichEditor from "./messageSend/RichEditor";

    export default {
        name: 'presentation',
        components: {
            DialogWrapper,
            GlobalPageBack,
            RichEditor,
        },
        data() {
            return {
                loading: true,
                isEdit: false,
                isEmpty: true,
                schoolId: '',
                showData: {
                    title: '',
                    logo: '',
                    content: '',
                },
                rulesForm: {
                    title: '',
                    content: ''
                },
                rules: {
                    title: [{
                        required: true,
                        message: '请输入标题',
                        trigger: 'blur'
                    }],
                    content: [{
                        required: true,
                        message: '请输入学校介绍内容',
                        trigger: 'blur'
                    }],
                },
                // 弹出框配置
                dialogObj: {
                    title: '校园介绍预览',
                    dialogVisible: false,
                    width: '400px'
                },
            }
        },
        computed: {
            imgEdit() {
                return require('../../assets/images/edit.png')
            }
        },
        created() {
            this.schoolId = this.$store.state.schoolId
            this.init()
        },
        mounted() {
            if (this.isEdit) {
                this.$eventDispatch('setGlobalPageType', 'form')
            } else {
                this.$eventDispatch('setGlobalPageType', 'list')
            }
        },
        activated() {
            if (this.isEdit) {
                this.$eventDispatch('setGlobalPageType', 'form')
            } else {
                this.$eventDispatch('setGlobalPageType', 'list')
            }
        },
        methods: {
            init() {
                this.loading = true
                this._fet('/school/common/introduce?schoolId=' + this.schoolId).then((res) => {
                    this.handleRes(res, () => {
                        if (res.data.data.length > 0 && res.data.data[0].schoolTitle && res.data.data[0].schoolIntroduce) {
                            this.isEmpty = false
                            this.showData.title = res.data.data[0].schoolTitle
                            this.showData.logo = res.data.data[0].logo
                            this.showData.content = res.data.data[0].schoolIntroduce
                        } else {
                            this.isEmpty = true
                        }
                    })
                }).finally(() => {
                    this.loading = false
                })
            },
            save() {
                this.$refs.formRef.validate((val) => {
                    if (val) {
                        this._fet('/school/common/editIntroduce', {
                            schoolId: this.schoolId,
                            schoolTitle: this.rulesForm.title,
                            schoolIntroduce: encodeURIComponent(this.rulesForm.content)
                        }).then((res) => {
                            this.handleRes(res, () => {
                                this.$message.success('编辑成功')
                                this.cancel()
                                this.init()
                            })
                        })
                    }
                })
            },
            preview() {
                this.dialogObj.dialogVisible = !this.dialogObj.dialogVisible
            },
            edit() {
                this.rulesForm = JSON.parse((JSON.stringify(this.showData)))
                this.isEdit = !this.isEdit
                this.$eventDispatch('setGlobalPageType', 'form')
            },
            cancel() {
                this.isEdit = !this.isEdit
                this.$eventDispatch('setGlobalPageType', 'list')
            },
            // 接口请求结果处理
            handleRes(res, fn) {
                if (res.data.code === '200') {
                    fn()
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .presentation {
        height: calc(100vh - 152px);
        overflow: auto;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 10px 240px 0px;
        position: relative;
        margin-top: 10px;
    }

    .img-wrapper {
        position: absolute;
        top: 60px;
        right: 240px;
        width: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            width: 12px;
            height: 12px;
        }

        p {
            margin-left: 7px;
            font-size: 12px;
            color: #3C7FFF;
        }
    }

    .hover-color:hover {
        color: #6db1f5;
    }

    .introduce-title-wrapper {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 20px;

        .text-conter {
            padding: 0 20px;
        }
    }

    .introduce-title {
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        color: #595959;
        text-align: center;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 20px;

    }

    .introduce-content {
        margin-top: 20px;
        word-wrap: break-word;
        font-size: 15px;
        line-height: 25px;
        color: #606266;
    }

    .introduce-empty {
        margin-top: 20vh;
        text-align: center;
        color: #666666;
        font-size: 14px;
        line-height: 27px;

        .create-btn {
            width: 137px;
            height: 33px;
            background: #2166ee;
            border-radius: 6px;
            border: 0;
            margin-top: 15px;
        }
    }
</style>
