<template>
    <el-select ref="elSelect" :disabled="treeOption.disabled" filterable clearable v-model="filterText"
               @visible-change="visibleChange" @clear="handleClear" :placeholder="treeOption.placeholder"
               :filter-method="change"
               :style="{'width': treeOption.width || '100%'}">
        <el-option :value="treeOption.list" label="">
            <el-tree
                node-key="id"
                ref="treeRef"
                :data="treeOption.treeData"
                :props="treeOption.defaultProps"
                :filter-node-method="filterNode"
                :expand-on-click-node="true"
                @node-click="handleClick"
                :default-expand-all="treeOption.expandAll"
                class="select-tree"
            />
        </el-option>
    </el-select>
</template>

<script>
import {throttle} from 'common-local'

export default {
    name: "subjectFilter",
    props: {
        treeShowData: {
            type: String
        },
        treeOption: {
            type: Object,
        },
        ruleForm: {
            type: Object
        }
    },
    data() {
        return {
            filterText: '',
        }
    },
    watch: {
        'treeShowData': {
            handler(val) {
                this.filterText = val
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        visibleChange(data) {
            if (data) {
                this.change()
            }
        },
        handleClear() {
            this.$emit('clearTree')
        },
        handleClick(data) {
            this.$emit('treeClick', data)
        },
        filterNode(value, data) {
            if (!value) return true;
            return data[this.treeOption.defaultProps.label].indexOf(value) !== -1;
        },
        change: throttle(function (val) {
            this.$refs.treeRef.filter(val);
        }, 200)
    }
}
</script>

<style scoped>
.select-tree {
    width: calc(100% + 40px);
    margin-left: -20px;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
}

.el-select-dropdown__item.selected {
    font-weight: normal;
}

ul li >>> .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 20px;
}

.el-tree-node__label {
    font-weight: normal;
}

.el-tree >>> .is-current .el-tree-node__label {
    color: #409EFF;
}

.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
    color: #606266;
    font-weight: normal;
}
</style>
